/**
 * ES5 compatible Link Resolver for Sanity.
 */

import { UrlDictionary, IDocumentRef } from "lib/global.types"

export const baseUrls: UrlDictionary = {
  "da-dk": "",
}

export const articleBase: UrlDictionary = {
  "da-dk": "/artikler",
}

const linkResolver = (doc: IDocumentRef): string => {
  // The "doc" object contains three things: lang, type and uid
  // The "dict" is an object that enables us to look up parts of the url, based on the type.
  // The "baseUrl" is the i18n root url.

  const baseUrl = baseUrls["da-dk"] // Is either empty string or 'en'

  let urlParts = ""

  switch (doc._type) {
    // Add a URL pattern for each doc.type that should result in a page.
    // E.g. if blog posts should be accessible on /blog
    case "page":
      if (doc.slug.path !== "index") {
        urlParts = `${doc.slug.path || doc.slug.current}`
      }
      break
    case "article":
      urlParts = `${articleBase["da-dk"]}/${doc.slug.path || doc.slug.current}`
      break
    default:
      urlParts = doc.slug ? `${doc.slug.path || doc.slug.current}` : ""
  }

  return `${baseUrl === "" ? "/" : `${baseUrl}`}${urlParts}`
}

export { linkResolver }
