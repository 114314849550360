import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "components/Layout"
import { PageBuilder } from "components/PageBuilder"
import { SEO } from "components/SEO"
import { IDocumentRef } from "lib/global.types"
import { linkResolver } from "utils/linkResolver"

type DataProps = {
  sanityPage: {
    pageBuilder: any
  }
}

const Page: React.FC<PageProps<DataProps>> = ({
  data: {
    sanityPage: { _type, slug, pageBuilder, pageMeta },
  },
}) => {
  const currentPage: IDocumentRef = { _type, slug }
  const child = linkResolver(currentPage)
  const url = [child].join("")
  return (
    <>
      <SEO slug={url} {...pageMeta} />
      <Layout>
        <PageBuilder sections={pageBuilder} />
      </Layout>
    </>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    sanityPage(_id: { eq: $id }) {
      _id
      pageBuilder {
        ... on SanityCta {
          _key
          _type
          themeColor
        }
        ... on SanityParagraph {
          _key
          _type
          themeColor
          container
          _rawContent
          cta {
            themeColor
            ctaText
            ctaLink {
              internal {
                slug {
                  current
                }
              }
              external
            }
          }
        }
        ... on SanitySection {
          _key
          _type
          themeColor
          container
          showAngle
          removeBottomSpacing
          content {
            gridConfig
            gapConfig
            itemNumbers
            key: _key
            gridItems {
              ... on SanityImageBox {
                ...ImageBoxFragment
              }
              ... on SanityBox {
                ...BoxFragment
              }
            }
          }
        }
        ... on SanityPhotoGrid {
          _key
          _type
          images {
            _key
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            cta {
              themeColor
              ctaText
              ctaLink {
                internal {
                  slug {
                    current
                  }
                }
                external
              }
            }
          }
        }
        ... on SanityPizzaMenu {
          _key
          _type
          pizzas {
            _key
            description
            price
          }
        }
        ... on SanityCta {
          themeColor
          ctaText
          ctaLink {
            internal {
              slug {
                current
              }
            }
            external
          }
        }
      }
      pageMeta {
        metaDescription
        metaImages {
          asset {
            url
          }
        }
        metaTitle
      }
      ...SanityPageLinkFragment
    }
  }

  fragment SanityPageLinkFragment on SanityPage {
    _type
    slug {
      path: current
    }
  }
`
