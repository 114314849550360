import React from 'react';
import 'twin.macro'

export const KvpList = ({ list }) => {
  return (
    <table tw="table-fixed w-full">
      <tbody>
      <tr>
        <th tw="w-2/3"></th>
        <th tw="w-1/3"></th>
      </tr>
        {list.map(({_key, key, value}, i) => (
          <tr key={_key}>
            <td tw="px-4 py-2 text-3xl">{key}</td>
            <td tw="px-4 py-2 text-3xl font-bold">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};
