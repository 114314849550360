import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { IBoxProps, IBoxConfig } from "./box.types"
import styled from "styled-components"
import { Content } from "components/Content"
import { CallToAction } from '../../lib/CallToAction'
import { KvpList } from '../../lib/KvpList'

/**
 * Boxes can be styled with brandStyles as well.
 * You can also create a new BrandThemeDictionary in the theme folders index file.
 */

const StyledBox = styled.article<IBoxConfig>`
  ${({ themeColor, theme }) =>
    themeColor ? theme.brandStyles[themeColor] : theme.brandStyles.default}

  p + p {
      margin-top: .25em;
  }

  ${props => props.hasBorder && tw`border-2 border-black`}
`

export const Box: React.FC<IBoxProps> = ({ children, content, ...props }) => {
  return (
    <StyledBox
      hasBorder={props.hasBorder}
      tw="text-center py-20 px-24 flex flex-col"
      {...props}
    >
      <Content blocks={content}/>
      {props?.kvpList && (
          <KvpList list={props.kvpList} />
        )}
      {props?.cta && (
          <CallToAction cta={props.cta} />
        )}
    </StyledBox>
  )
}

export const query = graphql`
  fragment BoxFragment on SanityBox {
    key: _key
    type: _type
    content: _rawContent
    themeColor
    kvpList {
      _key
      key
      value
    }
    hasBorder
    cta {
      themeColor
      ctaText
      ctaLink {
        internal {
          slug {
            current
          }
        }
        external
      }
    }
  }
`
