import React from 'react'
import 'twin.macro'
import styled from 'styled-components'
import { Container } from "../../components/Container"
import { PizzaMenuProps } from './PizzaMenu.types'

export const PizzaMenu: React.FC<PizzaMenuProps> = ({data}) => {
  
  return (

    <Container>
      <div tw="max-w-5xl mx-auto px-4">
        {data.pizzas.map((pizza, i) => (
          <div style={{justifyContent: "space-evenly"}} tw="flex mb-12 items-center space-x-12">
            {(i === 0 || i === 6) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-yellow text-white">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {(i === 1 || i === 7) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-green text-white">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {(i === 2 || i === 8) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-pink text-white">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {(i === 3 || i === 9) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-lightRed text-black">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {(i === 4 || i === 10) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-menuRed text-black">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {(i === 5 || i === 11) && (
              <div tw="flex justify-center items-center rounded-full text-3xl md:text-4xl w-16 h-16 overflow-hidden font-semibold bg-darkGreen text-white">
                <span>
                  {i + 1}
                </span>
              </div>
            )}
            {pizza.description && (
              <div tw="w-1/2 md:w-10/12 text-3xl md:text-4xl">{pizza.description}</div>
            )}
            {pizza.price && (
              <div tw="text-5xl font-semibold">{pizza.price}</div>
            )}
          </div>
        ))}
      </div>
    </Container>
  )
}