import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import Facebook from "./Facebook"
import { ISEOProps } from "./seo.types"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export const SEO: React.FC<ISEOProps> = ({
  metaTitle,
  metaDescription,
  slug,
  metaImages,
  metaTitlePrefix,
  metaTitleAppendix,
  siteLang,
  hideFromGoogle,
}) => {
  const {
    site: {
      siteMetadata: {
        siteUrl,
        metaTitlePrefix: defaultPrefix,
        metaTitleAppendix: defaultAppendix,
      },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          metaTitlePrefix
          metaTitleAppendix
        }
      }
    }
  `)

  const title: string = `${
    metaTitlePrefix ? metaTitlePrefix : defaultPrefix
  }${metaTitle}${metaTitleAppendix ? metaTitleAppendix : defaultAppendix}`

  const currentSlug: string = `${siteUrl}${slug}`

  return (
    <>
      <Helmet title={title}>
        <html lang={siteLang} />
        <meta name="description" content={metaDescription} />
        <meta name="image" content={`${metaImages[0].asset.url}`} />
        <link rel="canonical" href={currentSlug} />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WP1RNRYJQH"
        />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="a72dad16-bd69-4b32-9b16-792a00deba69"
          // data-blockingmode="auto"
          type="text/javascript"
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-WP1RNRYJQH');`}
        </script>
        {hideFromGoogle && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Facebook
        desc={metaDescription}
        title={title}
        type="website"
        url={currentSlug}
        locale={siteLang?.split("-")[0] || "da"}
      >
        {metaImages.map(({ asset }, idx) => {
          return [
            <meta
              key={`meta_img_${idx}`}
              property="og:image"
              content={`${asset.url}`}
            />,
          ]
        })}
      </Facebook>
    </>
  )
}
