import React from "react"
import Image from "./"
import calculateStyles from "@sanity/imagetool/calculateStyles"
import { IFluidImageProps } from "./image.types"
import styled from "styled-components"

const StyledContainer = styled.div`
  min-height: 100%;
`

export const Fluid: React.FC<IFluidImageProps> = ({
  hotspot,
  crop,
  asset,
  alt,
  ...props
}) => {
  // If no hotspot or crop, we're good to go with regular GatsbyImage
  if (!hotspot && !crop) {
    return <Image alt={alt} fluid={asset.fluid} {...props} />
  }

  // If we do, however, let's get each element's styles to replicate the hotspot and crop
  const targetStyles = calculateStyles({
    container: {
      aspectRatio: asset.fluid.aspectRatio,
    },
    image: {
      aspectRatio: asset.fluid.aspectRatio,
    },
    hotspot,
    crop,
  })

  // Unfortunately, as we need an extra wrapper for the image to apply the crop styles, we recreate a padding div and add it all to a new container div
  return (
    <StyledContainer style={targetStyles.container}>
      <div aria-hidden="true" style={targetStyles.padding} />
      <Image
        fluid={asset.fluid}
        alt={alt}
        style={targetStyles.crop}
        imgStyle={targetStyles.image}
      />
    </StyledContainer>
  )
}
