import React from "react"
import styled from "styled-components"
import "twin.macro"
import BlockContent from "@sanity/block-content-to-react"
import { IContentProps } from "./content.types"
import { Icon } from "lib/Icon"
import { Button } from "lib/Button"

const StyledTextInBlack = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2rem;

  & > div {
    background-color: #000;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  span {
    padding: 0;
  }
`

const ctaRenderer = (props: any) => {
  const handler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    window.open(props.node.external, "_blank", "noopener,noreferrer")
  }
  return (
    <a href={props.node.external} target="_blank" rel="noopener noreferrer">
      <Button onClick={e => handler(e)} themeColor="pink">
        {props.node.buttonTitle.toUpperCase()}
      </Button>
    </a>
  )
}

const BlockRenderer = (props: any) => {
  const { style = "normal" } = props.node

  if (style === "h1") {
    return (
      <>
        <h1>{props.children}</h1>
        <Icon tw="w-16 h-16 mb-12" icon="divider-tegn" />
      </>
    )
  }

  if (style === "numberInBlack") {
    return (
      <StyledTextInBlack>
        <div>{props.children}</div>
      </StyledTextInBlack>
    )
  }

  // Fall back to default handling
  // @ts-ignore
  return BlockContent.defaultSerializers.types.block(props)
}

const price = (props: any) => {
  return <span tw="font-bold text-5xl">{props.children}</span>
}

export const Content: React.FC<IContentProps> = ({ blocks, ...props }) => {
  return (
    <div tw="text-4xl">
      <BlockContent
        blocks={blocks}
        {...props}
        serializers={{
          types: { block: BlockRenderer, ctaLink: ctaRenderer },
          marks: { price },
        }}
      />
    </div>
  )
}
