import React from 'react'
import 'twin.macro'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { CallToAction} from '../CallToAction'
import { PhotoGridProps } from './photoGrid.types'

const StyledPhotoGrid = styled.section`
  grid-template-columns: repeat(2, minmax(0, 1fr));
  ${props => props.theme.media.tablet_portrait_up`
    grid-template-columns: repeat(${props => props.cols}, minmax(0, 1fr));
  `}
`

export const PhotoGrid: React.FC<PhotoGridProps> = ({data}) => {

  let cols
  const numberOfImages = data.images.length

  // Lets make a few scenarios for when certain number of images are present
  if (numberOfImages < 5) {
    cols = numberOfImages
  } else if (numberOfImages < 10) {
    cols = Math.round(numberOfImages / 2)
  } else if (numberOfImages > 9) {
    cols = 5
  }
  
  return (
    <StyledPhotoGrid tw="grid gap-4" cols={cols}>
      {data.images.map((image, i) => (
        <React.Fragment key={image._key}>
          {image?.asset?.fluid && (
            <>
              {image.cta && ( // If there is a Call To Action link, use it
                <CallToAction cta={image.cta}>
                  <Img fluid={{...image.asset.fluid, aspectRatio: 1 / 1}} />
                </CallToAction>
              )}
              {!image.cta && ( // No Call To Action provided in Sanity
                <Img fluid={{...image.asset.fluid, aspectRatio: 1 / 1}} />
              )}
            </>
          )}
          {/* {i} */}
        </React.Fragment>
      ))}
    </StyledPhotoGrid>
  )
}