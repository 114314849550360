import { IParagraphProps } from "components/PageBuilder/pagebuilder.types"
import React from "react"
import styled from 'styled-components'
import "twin.macro"
import { Content } from "components/Content"
import { Icon } from "lib/Icon"
import BlockContent from "@sanity/block-content-to-react"

const StyledTextInBlack = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    background-color: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }

  span {
    padding: 0;
  }
`

const BlockRenderer = (props: any) => {
  const { style = "normal" } = props.node

  if (style === "h1") {
    return (
      <>
        <h1>{props.children}</h1>
        <Icon tw="w-16 h-16 mb-12" icon="divider-tegn" />
      </>
    )
  }

  if (style === "numberInBlack") {
    return (
      <StyledTextInBlack>
        <div>
          {props.children}
        </div>
      </StyledTextInBlack>
    )
  }

  // Fall back to default handling
  // @ts-ignore
  return BlockContent.defaultSerializers.types.block(props)
}

export const Paragraph: React.FC<IParagraphProps> = ({ content, ...props }) => {
  return (
    <Content
      tw="text-center"
      blocks={content}
      serializers={{ types: { block: BlockRenderer } }}
      {...props}
    />
  )
}
