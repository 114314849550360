import React from "react"
import tw from "twin.macro"
import styled from 'styled-components'
import { IPageBuilderProps, SectionType } from "./pagebuilder.types"
import { Section } from "components/Section"
import { Paragraph } from "components/Paragraph"
import { GridBuilder } from "components/GridBuilder"
import { CallToAction } from '../../lib/CallToAction'
import { PhotoGrid } from '../../lib/PhotoGrid'
import { PizzaMenu } from '../../lib/PizzaMenu'

const Angle = styled.svg`
  height: 10%;

  ${props => props.theme.media.tablet_portrait_up`
    height: 15%;
  `}
  ${props => props.theme.media.desktop_up`
    height: 20%;
  `}
`

export const PageBuilder: React.FC<IPageBuilderProps> = ({
  sections,
  ...props
}) => {
  function renderType(type: SectionType, data: any) {

    switch (type) {
      case "paragraph":
        return (
          <div tw="pb-32">
            <Paragraph tw="md:max-w-6xl md:mx-auto" content={data._rawContent} />
            {data?.cta && (
              <CallToAction cta={data.cta} />
            )}
          </div>
        )
      case "section":
        return (
          <div css={[tw``, data.showAngle && tw`relative`]}>
          <GridBuilder content={data.content} />
           {data.showAngle && (
              <Angle tw="fill-current text-creme absolute right-0 bottom-0 w-full transform translate-y-1" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon points="0,50 100,75 100,100 0,100"></polygon>
                
              </Angle>
            )}
          </div>
        )
      case "photoGrid":
        return (
          <PhotoGrid data={data} />
        )
      case "pizzaMenu":
        return (
          <PizzaMenu data={data} />
        )
      case "cta":
        return (
          <CallToAction cta={data} />
        )
      default:
        return null
    }
  }

  return (
    <>
      {sections.map(({ _key, _type, container, themeColor, removeBottomSpacing, ...rest }) => {
        rest.themeColor = themeColor // Adding this back in to render the CTA color
        return (
          <Section
            key={_key}
            container={container}
            removeBottomSpacing={removeBottomSpacing}
            themeColor={_type !== 'cta' && themeColor}
            {...props}
          >
            {renderType(_type, rest)}
          </Section>
        )
      })}
    </>
  )
}
