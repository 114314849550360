import React from "react"
import "twin.macro"
import {
  IGridBuilderProps,
  IGridProps,
  IGridConfig,
  IGridItem,
} from "./gridbuilder.types"
import styled from "styled-components"
import { GridItem } from "./Item"
import { ImageBox } from "components/ImageBox"
import { IImageBoxProps } from "components/ImageBox/imagebox.types"
import { Box } from "components/Box"
import { IBoxProps } from "components/Box/box.types"

/**
 * A simple wrapper around children, to perform common layout adjustments.
 * Edit available brandStyles in theme/index.ts
 */

const StyledGridBuilder = styled.div<IGridConfig>`
  ${({ gridConfig, theme }) =>
    gridConfig ? theme.gridConfigs[gridConfig] : theme.gridConfigs.oneCol}

  ${({ gapConfig, theme }) =>
    gapConfig ? theme.gapConfigs[gapConfig] : theme.gapConfigs.regular}

  ${props => props.i = 0 && tw`-mt-12`}
`

export const GridBuilder: React.FC<IGridBuilderProps> = ({
  content,
  ...props
}) => {
  function renderGridItemContent(gridItem: IGridItem) {
    switch (gridItem.type) {
      case "imageBox":
        const { image, ...rest } = gridItem as IImageBoxProps
        return <ImageBox image={image} {...rest} />
      case "box":
        const item = gridItem as IBoxProps
        return <Box {...item} />
      default:
        return null
    }
  }

  return (
    <div>
      {content.map(
        ({ gridConfig, gapConfig, gridItems, key, ...rest }: IGridProps, i) => {
          return (
            <StyledGridBuilder
              key={key}
              gridConfig={gridConfig}
              gapConfig={gapConfig}
              i={i}
              tw="py-12"
              {...rest}
            >
              <>
                {gridItems.map(gridItem => {
                  return (
                    <GridItem key={gridItem.key}>
                      {renderGridItemContent(gridItem)}
                    </GridItem>
                  )
                })}
              </>
            </StyledGridBuilder>
          )
        }
      )}
    </div>
  )
}
