import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { IGatsbyImageProps } from "./image.types"

const StyledImage = styled(Img)`
  object-fit: cover;
`

export const Image: React.FC<IGatsbyImageProps> = ({ ...props }) => {
  return <StyledImage {...props} />
}

export default Image
