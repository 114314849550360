import React from "react"
import tw from "twin.macro"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Fluid } from "components/Image/Fluid"
import { IImageBoxProps } from "./imagebox.types"
import { IBoxProps } from "components/Box/box.types"
import { Content } from "components/Content"

/**
 * Boxes can be styled with brandStyles as well.
 * You can also create a new BrandThemeDictionary in the theme folders index file.
 */

const StyledImageBox = styled.section<IBoxProps>`
  ${({ themeColor, theme }) =>
    themeColor ? theme.brandStyles[themeColor] : theme.brandStyles.default}
`

const StyledCover = styled.figcaption`
  h2 {
    font-size: 5rem;
    font-weight: 300;
  }
  h3 {
    font-size: 2rem;
  }

  @media (min-width: 600px) {
    h2 {
      font-size: 5rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 7rem;
    }
    h3 {
      font-size: 3.6rem;
    }
  }
`

const StyledCircle = styled.figcaption`
  width: 200px;
  height: 200px;
  p {
    ${tw`text-2xl`};
  }
  h1,
  h2,
  h3 {
    font-size: 2rem;
    ${tw`font-button`};
  }
  h4 {
    font-size: 3rem;
    ${tw`font-button`};
  }

  ${props => props.theme.media.tablet_portrait_up`
      width: 280px;
      height: 280px;
      p {
        ${tw`text-3xl`};
      }
      h1, h2, h3 {
        font-size: 3rem;
        ${tw`font-button`};
      }
      h4 {
        font-size: 4rem;
        ${tw`font-button`};
      }
  `}
`

const StyledFluid = styled(Fluid)`
  max-height: ${props => props.setMaxHeight && "50vh"};
`

export const ImageBox: React.FC<IImageBoxProps> = ({
  image: { asset, alt, hotspot, crop },
  children,
  _rawContent,
  encircle,
  setMaxHeight,
  link,
  ...props
}) => {
  const hasOverlayText =
    _rawContent && _rawContent.length > 0 && !encircle ? true : false // If there is content, set this flag to true so we can add it on top of the image
  const hasOverlayTextEncircled =
    _rawContent && _rawContent.length > 0 && encircle ? true : false // If there is content, AND its enclosed in circle, set this flag to true so we can add it on top of the image
  return (
    <StyledImageBox
      as={link?.slug?.current ? Link : "section"}
      to={link?.slug?.current ? link?.slug?.current : ""}
      {...props}
      css={[tw``, (hasOverlayText || hasOverlayTextEncircled) && tw`relative`]}
    >
      <StyledFluid
        alt={alt}
        hotspot={hotspot}
        crop={crop}
        asset={asset}
        setMaxHeight={setMaxHeight}
      />
      {hasOverlayText && (
        <StyledCover tw="bg-black bg-opacity-25 absolute inset-0 w-full h-full flex justify-center items-center text-white text-center">
          <Content blocks={_rawContent} />
        </StyledCover>
      )}
      {hasOverlayTextEncircled && (
        <StyledCircle tw="bg-black absolute top-8 right-8 flex justify-center items-center text-white rounded-full text-center">
          <Content blocks={_rawContent} />
        </StyledCircle>
      )}
    </StyledImageBox>
  )
}

export const query = graphql`
  fragment ImageBoxFragment on SanityImageBox {
    key: _key
    type: _type
    _rawContent(resolveReferences: { maxDepth: 10 })
    encircle
    setMaxHeight
    image {
      alt
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
      crop {
        bottom
        left
        right
        top
      }
      hotspot {
        y
        x
        width
        height
      }
    }
    link {
      slug {
        current
      }
    }
  }
`
