import React from 'react';
import {Link} from 'gatsby';
import 'twin.macro'
import { CallToActionTypes } from 'lib/CallToAction/callToAction.types'

import { Button } from '../Button'

export const CallToAction: React.FC<CallToActionTypes> = ({cta, children}) => {
  return (
    <>
      {children && ( // If there are children, just wrap the children in a Link
        <>
          {cta?.ctaLink?.internal?.slug?.current && ( // If Internal
             <Link to={cta.ctaLink.internal.slug.current}>{children}</Link>
          )}
          
          {cta?.ctaLink?.external && !(cta?.ctaLink?.internal?.slug?.current) && ( // If External
             <a href={cta.ctaLink.external}>{children}</a>
          )}
        </>
      )}

      {!children && ( // If there are no children, Make a button using the button text
        <div tw="flex justify-center pt-8">
        {cta?.ctaLink?.internal?.slug?.current && cta?.ctaText && ( // If Internal
          <Button themeColor={cta?.themeColor ? cta.themeColor : ''} tw="text-4xl">
            <Link to={`/${cta.ctaLink.internal.slug.current}`}>{cta.ctaText}</Link>
          </Button>
        )}
            
        {cta?.ctaLink?.external && cta?.ctaText && !(cta?.ctaLink?.internal?.slug?.current) && ( // If External
          <Button themeColor={cta?.themeColor ? cta.themeColor : ''}>
              <a href={cta.ctaLink.external}>{cta.ctaText}</a>
            </Button>
        )}
      </div>
      )}
    </>
  )
}

